<template>
  <v-sheet>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title @click="addWidth()">
              Kitchen {{ value.x }}
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="model"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                color="white"
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                label="Public APIs"
                placeholder="Start typing to Search"
                prepend-icon="mdi-database-search"
                return-object
              ></v-autocomplete>
            </v-card-text>
            <v-divider></v-divider>
            <v-expand-transition>
              <v-list v-if="model" class="red lighten-3">
                <v-list-item v-for="(field, i) in fields" :key="i">
                  <v-list-item-content>
                    <v-list-item-title v-text="field.value"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="field.key"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-row>
              <v-col cols="6">
                <qrcode-stream
                  v-if="cameraOn"
                  :key="_uid"
                  :track="selected.value"
                  @init="logErrors"
                />
              </v-col>
              <v-col cols="6">
                <v-btn @click="cameraOn = !cameraOn"
                  ><v-icon>mdi-camera</v-icon></v-btn
                >
                <h3>{{ inventorySearch }}</h3>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import * as Utils from "../classes/classes";
import { QrcodeStream } from "vue-qrcode-reader";
// import { firebaseApp } from "../firebase/init";
// import "firebase/firestore";

export default {
  components: { QrcodeStream },
  name: "KitchenInventory",
  data: function () {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      value: new Utils.Rectangle(2, 3),
      cameraOn: false,
      groups: [
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
        {
          name: "Hoffman",
          startDate: "April 17, 2022",
          endDate: "April 24, 2022",
        },
        {
          name: "Buddhist Group",
          startDate: "April 30, 2022",
          endDate: "May 2, 2022",
        },
      ],
      options: [
        { text: "nothing (default)", value: undefined },
        { text: "outline", value: this.paintOutline },
        { text: "centered text", value: this.paintCenterText },
        { text: "bounding box", value: this.paintBoundingBox },
      ],
      selected: { text: "centered text", value: this.paintCenterText },
      inventorySearch: "",
    };
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },
  },
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;
        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;
        const fontSize = Math.max(
          12,
          (50 * boundingBox.width) / ctx.canvas.width
        );
        console.log(boundingBox.width, ctx.canvas.width);
        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = "center";
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#ffffff";
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);
        ctx.fillStyle = "#ffffff";
        ctx.fillText(rawValue, centerX, centerY);
        if (this.inventorySearch !== rawValue) this.inventorySearch = rawValue;
      }
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
    addWidth() {
      this.value.addWidthFunction(1);
    },
  },
  watch: {
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch("https://api.publicapis.org/entries")
        .then((res) => res.json())
        .then((res) => {
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
